import {ItemTableConfig, ItemTable} from 'elements/itemtable';
import {Column, DateColumn} from 'elements/itemtable/column';
import {FilterConfig} from 'elements/itemtable/filter';

export class Promos extends ItemTable {
	protected readonly title = 'Promo Codes';
	public readonly webService = 'promocodes';
	public readonly config: ItemTableConfig = {
		columns: [
			'Code',
			new Column('Description', 'name'),
			new DateColumn('Created', 'createdAt'),
			new DateColumn('Expires')
		],
		filters: [
			FilterConfig.statusFilter
		],
		search: {
			columns: ['code', 'name']
		}
	};
}
