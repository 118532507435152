import {ItemTableConfig, ItemTable} from 'elements/itemtable';
import {Column, SubtotalColumn} from 'elements/itemtable/column';
import {FilterConfig} from 'elements/itemtable/filter';

export class Kits extends ItemTable {
	protected readonly title = 'Tasting Kits';
	public readonly webService = 'kits';
	public readonly config: ItemTableConfig = {
		columns: [
			new Column('DO', 'sortOrder'),
			new Column('Tasting Kit Name', 'name'),
			new Column('Subtitle', undefined, 'lg'),
			new Column('Products', 'products.length'),
			new SubtotalColumn('Price', 'price')
		],
		filters: [
			FilterConfig.statusFilter
		],
		search: {
			columns: ['name', 'subtitle']
		}
	};
}
