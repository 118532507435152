import {ItemEditor, ItemEditorColumn, ItemEditorSection} from "elements/itemeditor";
import {ItemEditorInputField, ItemEditorDateField, ItemEditorBooleanField} from "elements/itemeditor/field";
import {InputField, NumericInputField} from "form";

export class Promo extends ItemEditor {
	public readonly webService = 'promocodes';
	protected readonly responseParam = 'promoCode';
	protected readonly itemType = 'Promo Code';

	protected readonly columns = [
		new ItemEditorColumn([
			new ItemEditorSection('Promo Code Details', [
				new ItemEditorInputField('code', 'Code', true, InputField),
				new ItemEditorInputField('name', 'Description', true),
				new ItemEditorDateField('expires', 'Expires', false, 'Never'),
				new ItemEditorBooleanField('isDisabled', 'Enabled', false, true)
			])
		]),
		new ItemEditorColumn([
			new ItemEditorSection('Promotion', [
				new ItemEditorInputField('extraBottles', 'Extra Bottles', false, NumericInputField),
				new ItemEditorInputField('percentOff', 'Percent Off', false, NumericInputField),
				new ItemEditorBooleanField('freeDelivery', 'Free Delivery')
			])
		]),
		new ItemEditorColumn([
			new ItemEditorSection('Valid For', [
				new ItemEditorBooleanField('validFor.tastingKit', 'Tasting Kit'),
				new ItemEditorBooleanField('validFor.clubOrder', 'Club Order'),
				new ItemEditorBooleanField('validFor.storeOrder', 'Store Order')
			])
		])
	];
}
