import {AppConfig} from "appconfig";
import {Form, InputField} from "form";
import {App} from "app";
import {autoinject, viewResources, PLATFORM} from "aurelia-framework";

@autoinject
@viewResources(PLATFORM.moduleName('elements/form'))
export class SignIn {
	public config: typeof AppConfig = AppConfig;
	public form: Form = new Form('signin', 'sign-in', [
		(new InputField('username', 'Username', true)).autoFocus(),
		new InputField('pass', 'Password', true, 'password')
	], 'Sign In');

	constructor(app: App) {
		this.form.onSuccess = () => {
			const {signinRedirect} = this.config;
			this.config.auth = true;
			if (signinRedirect && signinRedirect.config.name && signinRedirect.config.name !== 'signin') {
				app.router.navigateToRoute(signinRedirect.config.name, signinRedirect.params);
			} else {
				app.router.navigateToRoute('home');
			}
		}
	}
}
