import {WebService} from "webservice";
import {Chart, SegmentMapping} from "chart";
import {viewResources, PLATFORM} from "aurelia-framework";

@viewResources(PLATFORM.moduleName('elements/piechart'))
export class Home {
	private selectionRequired: number;
	private selectionUri: string;
	private selectionBanner: string;
	private charts: Chart[];
	
	private attached(): void {
		WebService.get('dashboard').then((data) => {

			this.selectionRequired = data.selectionRequired;
			this.selectionBanner = `${this.selectionRequired} ${this.selectionRequired === 1 ? 'member needs' : 'members need'} their next club wines selected.`;
			this.selectionUri = `/members/?customers[filter][Selection]=Required&customers[filter][Status]=Active`;

			this.charts = [
				new Chart('Club Members', 'Members', data.customers, [
					new SegmentMapping('completed', 'have completed ratings', '/members?customers[filter][Rated]=Yes&customers[filter][Status]=Active'),
					new SegmentMapping('incomplete', 'have not completed ratings', '/members?customers[filter][Rated]=No&customers[filter][Status]=Active'),
					new SegmentMapping('disabled', 'disabled accounts', '/members?customers[filter][Status]=Disabled')
				]),
				new Chart('Order Status', 'Orders', data.orderStatus, [
					new SegmentMapping('completed', 'completed orders', '/orders?orders[filter][Status]=Shipped'),
					new SegmentMapping('pending', 'pending orders', '/orders?orders[filter][Status]=Pending'),
					new SegmentMapping('charged', 'orders to be packaged', '/orders?orders[filter][Status]=Charged')
				]),
				new Chart('Order Types', 'Orders', data.orderType, [
					new SegmentMapping('tastingKit', 'tasting kits', '/orders?orders[filter][Status]=All&orders[filter][Type]=Tasting Kit'),
					new SegmentMapping('clubOrder', 'club orders', '/orders?orders[filter][Status]=All&orders[filter][Type]=Club Order'),
					new SegmentMapping('storeOrder', 'store orders', '/orders?orders[filter][Status]=All&orders[filter][Type]=Store Order')
				]),
				new Chart('Order Customers', 'Orders', data.orderCustomerType, [
					new SegmentMapping('customer', 'orders from club members', '/orders?orders[filter][Status]=All&orders[filter][Customers]=All Members'),
					new SegmentMapping('guest', 'orders from guests', '/orders?orders[filter][Status]=All&orders[filter][Customers]=Guests'),
					new SegmentMapping('limitedCustomer', 'orders from limited accounts', '/orders?orders[filter][Status]=All&orders[filter][Customers]=All Limited Accounts')
				]),
				new Chart('Products', 'Products', data.products, [
					new SegmentMapping('active', 'active products', '/products?products[filter][Available]=Yes'),
					new SegmentMapping('disabled', 'disabled products', '/products?products[filter][Available]=No')
				])
			];
		});
	}
}
