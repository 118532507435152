import {ItemTable, ItemTableConfig} from "elements/itemtable";
import {DateColumn, Column} from "elements/itemtable/column";
import {FilterConfig, FilterOption} from 'elements/itemtable/filter';

export class Blog extends ItemTable {
	public readonly webService = 'blogposts';
	public readonly title = 'Blog Posts';
	protected defaultSort = '-publishedAt';

	public readonly config: ItemTableConfig = {
		columns: [
			new DateColumn('Published', 'publishedAt'),
			new DateColumn('Created', 'createdAt'),
			new Column('Title'),
			new Column('Tags')
		],
		filters: [
			new FilterConfig('Published', [
				new FilterOption('All', {}),
				new FilterOption('Yes', {isDisabled: {$ne: true}}),
				new FilterOption('No', {isDisabled: true})
			])
		],
		search: {
			columns: ['title', 'tags']
		}
	};
}
