import {ItemEditor, ItemEditorColumn, ItemEditorSection, ItemEditorContainer} from "elements/itemeditor";
import {ItemEditorInputField, ItemEditorUploadField, ItemEditorDateField, ItemEditorBooleanField, ItemEditorRichTextField} from "elements/itemeditor/field";
import {InputField} from 'form';

export class BlogPost extends ItemEditor {
	public readonly webService = 'blogposts';
	protected readonly responseParam = 'blogPost';

	protected readonly columns = [
		new ItemEditorColumn([
			new ItemEditorSection('Post Details', [
				new ItemEditorInputField('title', 'Title', true),
				new ItemEditorDateField('publishedAt', 'Publish Date/Time', false, 'Current date/time'),
				new ItemEditorInputField('tags', 'Tags'),
				new ItemEditorInputField('summary', 'Summary'),
				new ItemEditorBooleanField('isDisabled', 'Published', true, true)
			]),
			new ItemEditorContainer([
				new ItemEditorSection('Media', [
					new ItemEditorInputField('video', 'Video ID', false, InputField, 'Paste YouTube ID or URL'),
					new ItemEditorInputField('imageCredit', 'Photo Credit')
				]),
				new ItemEditorSection('Image', [
					new ItemEditorUploadField('image', '')
				], 'blocks')
			])
		], 'no-grow'),
		new ItemEditorColumn([
			new ItemEditorSection('Post Content', [
				new ItemEditorRichTextField('body', '', true)
			], 'blocks')
		], 'wide')
	];
}
