import {HttpClient, HttpResponseMessage} from 'aurelia-http-client';

export interface WebServiceResponse {
	success: boolean,
	error?: any,
	[index: string]: any
}

export class WebService {
	private static client = new HttpClient().configure((builder) => {
		builder.withBaseUrl('/ws');
	});
	private static respond({content, isSuccess}: HttpResponseMessage): Promise<WebServiceResponse> {
		return new Promise((resolve, reject) => {
			if (!isSuccess || !content || !content.success) return reject(content && content.error);
			resolve(content);
		});
	}
	public static get(uri: string, params?: {[index: string]: any}): Promise<WebServiceResponse> {
		return this.client.createRequest(uri).asGet().withParams(params).send().then(this.respond, this.respond);
	}
	public static post(uri: string, params?: {[index: string]: any}): Promise<WebServiceResponse> {
		return this.client.createRequest(uri).asPost().withHeader('Content-type', 'application/json').withContent(JSON.stringify(params)).send().then(this.respond, this.respond);
	}
	public static upload(uri: string, data: FormData, onProgress: (ProgressEvent) => void): Promise<WebServiceResponse> {
		return this.client.createRequest(uri + '/upload').asPost().withProgressCallback(onProgress).withContent(data).send().then(this.respond, this.respond);
	}
}
