import {autoinject, viewResources, PLATFORM} from 'aurelia-framework';
import {App} from 'app';
import {Route} from 'route';
import {WebService} from 'webservice';
import {copyValue} from 'utils';
import {DateFormatter} from 'dateFormatter';
import {Price} from 'price';
import {OkButton, YesButton, NoButton} from 'elements/dialog';
import {ProductData, addExtraBottles} from './order';

class Info {
	constructor(private readonly label: string, public value: string) {}
}

class CheckInResult {
	constructor(public readonly success: boolean, public readonly message: string) {}
}

class Product implements ProductData {
	public readonly product: ProductData['product'];
	public readonly quantity: number;
	public readonly subtotal: number;
	public readonly origPrice: number;
	public readonly unitPrice: number;
	public readonly image: string;
	public readonly originalImage: string;
	public isComplete: boolean = false;

	private readonly quantityInfo: Info;
	private readonly info: Info[];
	private checkedIn: number = 0;

	constructor(productData: ProductData, order: any) {
		Object.assign(this, productData);
		this.quantityInfo = new Info('Quantity', '0 of ' + this.quantity.toString());
		this.info = [
			new Info('Bin #', this.product.bin || 'N/A'),
			new Info('Vintage', this.product.year),
			new Info('UPC', this.product.upc),
			new Info('Price', '$' + Price.fromCents(this.unitPrice).toString()),
			this.quantityInfo
		];
		if (this.product.hasImage) {
			const imgPath = `${order.imageUri}/products/${this.product._id}_`;
			this.image = `${imgPath}300.jpeg`;
			this.originalImage = `${imgPath}original.jpeg`;
		} else {
			this.image = this.originalImage = '/assets/wines/generic.jpg';
		}
	}

	public checkIn(): CheckInResult {
		if (this.isComplete) return new CheckInResult(false, `All bottles of ${this.product.name} already checked in`);
		this.checkedIn++;
		this.quantityInfo.value = `${this.checkedIn} of ${this.quantity}`;
		this.isComplete = this.checkedIn >= this.quantity;
		return new CheckInResult(true, `${this.product.name}: ${this.checkedIn} of ${this.quantity}`);
	}
}

class Box implements BoxData {
	public readonly bottles: number;
	public readonly size: number;
	public readonly weight: number;
	public readonly base: number;
	public readonly subtotal: number;
	public readonly handling: number;
	public readonly total: number;
	public readonly upcInput: HTMLInputElement;

	public tracking: string;
	public barcode?: string;

	private readonly isShipping: boolean;
	private formattedTracking: string;
	private error?: string;

	constructor(rawData: BoxData, private readonly order: any) {
		Object.assign(this, rawData);
		this.isShipping = !!order.shippingData.provider;
	}

	public track(): boolean {
		const {trackingRegex}: {trackingRegex: RegExp} = this.order.shippingData.provider;
		const barcode = this.barcode.match(trackingRegex);
		this.barcode = '';
		this.error = '';

		if (!barcode) {
			this.error = 'Invalid tracking number';
			this.upcInput.focus();
			return false;
		}

		this.tracking = barcode.slice(1).join('');
		this.formattedTracking = this.tracking.match(trackingRegex).slice(1).join(' ');

		return true;
	}
	
	public reset(): boolean {
		this.barcode = '';
		this.error = '';
		this.tracking = '';
		this.formattedTracking = '';
		setTimeout(() => {
			this.upcInput.focus();
		}, 0);
		return false;
	}
}

class Beep {
	private readonly context: AudioContext = new AudioContext();
	private readonly gain: GainNode = this.context.createGain();

	constructor(private readonly frequency: number, gainValue: number, private readonly seconds: number, private readonly type?: OscillatorType) {
		this.gain.gain.value = gainValue;
		this.gain.connect(this.context.destination);
	}

	public emit(): void {
		const oscillator = this.context.createOscillator();
		oscillator.frequency.value = this.frequency;
		if (this.type) oscillator.type = this.type;

		oscillator.connect(this.gain);
		oscillator.start();
		oscillator.stop(this.context.currentTime + this.seconds);
	}
}

@autoinject
@viewResources(PLATFORM.moduleName('elements/address'))
export class Ship {
	private route: Route;
	private order: any;
	private error?: string;
	private status?: CheckInResult;
	private isLoading: boolean = true;
	private isPackaged: boolean = false;
	private isPrinted: boolean = false;
	private isTracked: boolean = false;
	private isComplete: boolean = false;
	private upcInput: HTMLInputElement;
	private barcode: string;
	private infoList: Info[];
	private boxes: Box[];
	private referralBottles: number = 0;
	private products: Product[];
	private beepSuccess: Beep = new Beep(1000, 0.5, 0.2);
	private beepError: Beep = new Beep(180, 0.25, 0.5, 'square');

	constructor(private readonly app: App) {}

	private attached(): void {
		const {currentInstruction} = this.app.router;
		this.route = currentInstruction.config.settings.route;

		WebService.post('orders/details', {id: currentInstruction.params.id}).then(({order}) => {
			if (order.shippedAt) return location.href = '/orders/' + order.orderId;
			if (!order.customer) order.customer = order.guest;

			const {shippingData} = order;
			const {provider, service, boxes} = shippingData;

			this.order = order;
			this.infoList = [
				new Info('Order ID', order.orderId),
				new Info('Customer', order.customer.firstName + ' ' + order.customer.lastName),
				new Info('Created', DateFormatter.format(new Date(order.createdAt))),
				new Info('Order Type', (order.isGift ? 'Gift ' : '') + order.type.replace(/(\w)(\w+?)([A-Z]\w+)/, (match, a, b, c) => a.toUpperCase() + b + ' ' + c))
			];
			if (order.type === 'tastingKit') this.infoList.push(new Info('Tasting Kit', this.order.kitName));
			this.infoList.push(
				new Info('Insert', this.getInsert()),
				new Info('Promo Code', order.promoCode && order.promoCode.code || 'None'),
				new Info('Shipping', provider ? provider.name + (service ? ' ' + service : '') : 'In-Store Pickup')
			);

			this.boxes = boxes.map((box) => new Box(box, order));
			this.referralBottles = addExtraBottles(this.order);
			if (provider) provider.trackingRegex = new RegExp(provider.trackingRegex[0], provider.trackingRegex[1]);

			const missingUpc: ProductData['product'][] = [];
			this.products = order.products.filter((productData) => productData.quantity > 0).map((productData) => {
				const product = new Product(productData, order);
				if (!product.product.upc && product.product._id !== 'promo') missingUpc.push(product.product);
				return product;
			});

			const isDisabled = order.customer && (order.customer.isDisabled || order.customer.isRemoved);
			if (isDisabled || missingUpc.length) {
				this.app.dialogContainer.open(
					isDisabled ? PLATFORM.moduleName('views/dialogs/customer-disabled.html') : PLATFORM.moduleName('views/dialogs/missing-upc.html'),
					isDisabled ? {} : {products: missingUpc},
					{
						heading: isDisabled ? 'Account Disabled' : 'Missing UPC',
						hideCloseButton: true,
						buttons: [
							new OkButton((dialog) => {
								dialog.closeAll();
								this.app.router.navigateToRoute('order', {id: order.orderId});
							})
						]
					}
				);
			}

			this.isLoading = false;
			setTimeout(() => this.upcInput.focus(), 0);
		}, (error) => {
			this.error = error;
			this.isLoading = false;
		});
	}

	private copyValue(value: string): void {
		copyValue(value);
		this.focusUpc();
	}

	private getInsert(): string {
		if (this.order.type === 'tastingKit') {
			if (this.order.isGift) return 'Gift: ' + this.order.kitName;
			return 'Welcome';
		}
		if (this.order.type === 'clubOrder') return 'Club Shipment';
		if (this.order.guest || this.order.customerType === 'limitedCustomer') return 'Store Order - Guest';
		return 'Store Order - Member';
	}

	private checkComplete(): boolean {
		this.isPackaged = !this.products.find((product) => !product.isComplete);
		this.isTracked = !this.order.shippingData.provider || !this.boxes.find((box) => !box.tracking);
		this.isComplete = this.isPackaged && this.isTracked;
		return this.isComplete;
	}

	private checkIn(product: Product): void {
		this.status = product.checkIn();
		if (this.checkComplete()) return;
		this.focusUpc();
	}

	private checkBarcode({key}: KeyboardEvent): boolean {
		if (key !== 'Enter' && key !== 'Tab' || !this.barcode) return true;
		const product = this.products.find((product) => product.product.upc === this.barcode);
		if (product) this.checkIn(product);
		else this.status = new CheckInResult(false, 'Invalid UPC or lookup code');
		this.barcode = '';
		this.beep(this.status.success);
		return false;
	}

	private checkTracking({key}: KeyboardEvent, box: Box): boolean {
		if (key !== 'Enter' && key !== 'Tab' || !box.barcode) return true;
		const success = box.track();
		this.beep(success);
		if (!success) return false;
		if (this.checkComplete()) return false;
		(this.isTracked ? this as any : this.boxes.find((box) => !box.tracking)).upcInput.focus();
		return false;
	}

	private focusUpc(): boolean {
		(this.isPackaged ? this.boxes.find((box) => !box.tracking) : this as any).upcInput.focus();
		return true;
	}

	public print(): void {
		if (this.isLoading || !this.order) return;
		this.isLoading = true;

		(new Promise((resolve, reject) => {
			if (!this.isPrinted || !this.order.shippingData.provider) return resolve();
			this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/confirm-reprint.html'), {}, {
				heading: 'Print Labels',
				buttons: [
					new YesButton((dialog) => {
						resolve();
						dialog.close();
					}),
					new NoButton((dialog) => {
						reject();
						dialog.close();
					})
				]
			});
		})).then(() => WebService.get('auth/getKey').then(({key}) => {
			if (!key) {
				this.error = 'Could not get authorization key';
				return;
			}
			this.isPrinted = true;
			let {provider} = this.order.shippingData;
			provider = provider && provider.id || 'pickup';
			location.href = `sipbetter://${location.hostname}/label/${provider}/${this.order._id}?key=${key}&referralBottles=${this.referralBottles}`;
		}, (error) => {
			this.error = error;
		})).finally(() => {
			this.isLoading = false;
		});
	}

	private ship(): void {
		if (this.isLoading || !this.order || !this.isComplete) return;
		this.isLoading = true;
		this.status = null;

		WebService.post('orders/ship', {id: this.order._id, referralBottles: this.referralBottles, tracking: this.trackingNumbers}).then(() => {
			if (window.opener) window.opener.dispatchEvent(new CustomEvent('sbItemChanged', {detail: 'orders'}));
			location.href = '/orders/' + this.order.orderId;
		}, (error) => {
			this.error = error;
			this.isLoading = false;
		});
	}

	private beep(success: boolean): void {
		this[success ? 'beepSuccess' : 'beepError'].emit();
	}

	private get trackingNumbers(): string[] {
		return this.order.shippingData.provider && this.boxes.map((box) => box.tracking);
	}
}

interface BoxData {
	bottles: number;
	size: number;
	weight: number;
	base: number;
	subtotal: number;
	handling: number;
	total: number;
	tracking: string;
}
