import {ItemTable, ItemTableConfig} from "elements/itemtable";
import {Column, EnumColumn, CountColumn, PriceColumn, DateColumn, ConcatColumn, IconColumn} from "elements/itemtable/column";
import {FilterConfig, FilterOption} from "elements/itemtable/filter";
import {Report} from "elements/itemtable/report";
import {DropdownSeparator} from "elements/dropdown";
import {App} from "app";
import {DateFormatter} from "dateFormatter";

const dateColumnFormatter = new DateFormatter({longFormat: false});

export class Orders extends ItemTable {
	protected readonly showNewButton = false;
	public readonly webService = 'orders';
	public readonly idColumn = 'orderId';
	protected readonly defaultSort = 'createdAt';
	
	public readonly config: ItemTableConfig = {
		columns: [
			new Column('Order ID'),
			new IconColumn('Gift', 'isGift', 'gift'),
			new EnumColumn('Type', 'type', {tastingKit: 'Tasting Kit', storeOrder: 'Store Order', clubOrder: 'Club Order'}),
			new IconColumn('Member', 'guest', 'ban', 'user-circle-o', 'user-circle-o'),
			new ConcatColumn('Customer Name', ['customer.lastName', 'customer.firstName']),
			new CountColumn('Items', undefined, undefined, undefined, (total, rawData) => {
				total += rawData.promoCode && rawData.promoCode.extraBottles || 0;
				total += rawData.shippedAt ? (rawData.referralBottles || 0) : (rawData.type === 'clubOrder' && rawData.customer.referralBottles || 0);
				return total;
			}),
			new PriceColumn('Total'),
			new Column('Shipping', 'shippingData.provider', undefined, 'In-Store Pickup'),
			new DateColumn('Created', 'createdAt', undefined, dateColumnFormatter),
			new DateColumn('Charged', 'chargedAt', undefined, dateColumnFormatter),
			new DateColumn('Shipped', 'shippedAt', undefined, dateColumnFormatter)
		],
		filters: [
			new FilterConfig('Status', [
				new FilterOption('All', {cancelledAt: null}),
				new FilterOption('Pending', {chargedAt: null, cancelledAt: null}),
				new FilterOption('Charged', {chargedAt: {$ne: null}, shippedAt: null, cancelledAt: null}, true),
				new FilterOption('Shipped in last 15d', {shippedAt: 15, cancelledAt: null}),
				new FilterOption('Shipped in last 30d', {shippedAt: 30, cancelledAt: null}),
				new FilterOption('All Shipped', {shippedAt: {$ne: null}, cancelledAt: null}),
				new DropdownSeparator(),
				new FilterOption('Cancelled', {cancelledAt: {$ne: null}})
			]),
			new FilterConfig('Type', [
				new FilterOption('All', {}),
				new FilterOption('Tasting Kit', {type: 'tastingKit'}),
				new FilterOption('Club Order', {type: 'clubOrder'}),
				new FilterOption('Store Order', {type: 'storeOrder'})
			]),
			new FilterConfig('Shipping', [
				new FilterOption('All', {}),
				new FilterOption('In-Store Pickup', {'shippingData.provider': null}),
				new FilterOption('Free Delivery', {'shippingData.provider': 'free'}),
				new FilterOption('Spee-Dee', {'shippingData.provider': 'speedee'}),
				new FilterOption('FedEx', {'shippingData.provider': 'fedex'}),
				new FilterOption('UPS', {'shippingData.provider': 'ups'})
			]),
			new FilterConfig('Customers', [
				new FilterOption('All', {}),
				new DropdownSeparator(),
				new FilterOption('All Members', {customer: {$ne: null}, customerType: 'customer'}),
				new FilterOption('Active Members', {customer: {$ne: null}, customerType: 'customer', 'customer.isDisabled': {$ne: true}}),
				new FilterOption('Disabled Members', {customer: {$ne: null}, customerType: 'customer', 'customer.isDisabled': true}),
				new DropdownSeparator(),
				new FilterOption('All Limited Accounts', {customer: {$ne: null}, customerType: 'limitedCustomer'}),
				new FilterOption('Active Limited Accounts', {customer: {$ne: null}, customerType: 'limitedCustomer', 'customer.isDisabled': {$ne: true}}),
				new FilterOption('Disabled Limited Accounts', {customer: {$ne: null}, customerType: 'limitedCustomer', 'customer.isDisabled': true}),
				new DropdownSeparator(),
				new FilterOption('Guests', {customer: null})
			])
		],
		reports: [
			new Report('All Orders', 'orders'),
			new Report('Awaiting Shipment', 'orders?unshipped'),
			new Report('Awaiting + Pending', 'orders?unshipped&pending'),
			new DropdownSeparator()
		],
		search: {
			columns: ['orderId', '__$customerName']
		}
	};

	constructor(app: App) {
		super(app);

		const firstMonth = (new Date(2017, 6, 1, 0, 0, 0, 0)).getTime();
		const date = new Date();
		date.setDate(1);
		date.setHours(0, 0, 0, 0);
		
		while (date.getTime() >= firstMonth) {
			const month = date.getMonth();
			const year = date.getFullYear();
			this.config.reports.push(new Report(
				`${DateFormatter.months[month]} ${year}`,
				`orders?month=${month}&year=${year}`
			));
			date.setDate(0);
			date.setDate(1);
		}
	}

	public parseItem(item: any): any {
		const {price} = item.gatewayData;
		if (item.guest) item.customer = item.guest;
		item.isDisabled = !!(item.customer && (item.customer.isDisabled || item.customer.isRemoved));
		item.total = price && (price.subtotal + price.tax.total + price.shipping);
		return item;
	}
}
