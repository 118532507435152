import {ItemEditor, ItemEditorColumn, ItemEditorSection, ItemEditorSectionButton, ItemEditorContainer} from 'elements/itemeditor';
import {ItemEditorStaticField, ItemEditorStaticTable, ItemEditorStaticCount, ItemEditorStaticDate, ItemEditorStaticPrice, ItemEditorStaticList, ItemEditorStaticAddress, ItemEditorStaticBoolean, ItemEditorDropdownField} from 'elements/itemeditor/field';
import {Column, PriceColumn} from 'elements/itemtable/column';
import {WebServiceResponse, WebService} from 'webservice';
import {getProperty} from 'utils';
import {YesButton, NoButton, OkButton} from 'elements/dialog';
import {PLATFORM} from 'aurelia-pal';
import {Price} from 'price';
import {Ship} from './ship';
import {Rolodex} from 'elements/rolodex';
import {Wallet} from 'elements/wallet';

export class Order extends ItemEditor {
	public readonly webService = 'orders';
	protected readonly responseParam = 'order';
	protected readonly allowSave = false;
	private order: any;
	private referralBottles: number = 0;

	protected readonly columns = [
		new ItemEditorColumn([
			new ItemEditorSection('Order Details', [
				new ItemEditorStaticField('orderId', 'Order ID'),
				new ItemEditorStaticField('type', 'Type', undefined, undefined, undefined, (value, rawData) => {
					return (rawData.isGift ? 'Gift ' : '') + value.replace(/(\w)(\w+?)([A-Z]\w+)/, (match, a, b, c) => a.toUpperCase() + b + ' ' + c);
				}),
				new ItemEditorStaticField('kitName', 'Tasting Kit'),
				new ItemEditorStaticCount('products', 'Total Items'),
				new ItemEditorStaticField('promoCode', 'Promo Code', undefined, undefined, 'None', function(value) {
					return this.originalParser(value && `${value.code}: ${value.name}`);
				}),
				new ItemEditorStaticField('promoCode.referralCustomer', 'Referred By', 'members', 'promoCode.referralCustomer._id', undefined, (value) => value && `${value.firstName} ${value.lastName}`),
				new ItemEditorStaticField('gatewayData.transactionId', 'Transaction ID', '', 'gatewayData.transactionUrl', 'Awaiting Transaction'),
				new ItemEditorStaticDate('createdAt', 'Created'),
				new ItemEditorStaticDate('chargedAt', 'Charged'),
				new ItemEditorStaticDate('shippedAt', 'Shipped'),
				new ItemEditorStaticDate('cancelledAt', 'Cancelled'),
				new ItemEditorStaticField('gatewayData.cardInfo', 'Payment Method', undefined, undefined, 'N/A', function(value) {
					return this.originalParser(value && `${value.cardholderName} / ${value.cardType} ending in ${value.last4}`)
				})
			]),
			new ItemEditorSection('Gift Message', [
				new ItemEditorStaticField('giftMessage', '')
			], 'blocks'),
			new ItemEditorSection('Shipment', [
				new ItemEditorStaticField('shippingData.provider.name', 'Shipping Service', undefined, undefined, 'In-Store Pickup'),
				new ItemEditorStaticList('shippingData.boxes', 'Tracking Number(s)', 'tracking', (rawData) => getProperty(rawData, 'shippingData.provider.trackingUrl'), 'tracking', 'Awaiting Shipment'),
				new ItemEditorStaticAddress('shippingData.destination', 'Shipping Address'),
				new ItemEditorStaticAddress('shippingData.destination.customerAddress', 'For')
			])
		], 'narrow'),
		new ItemEditorColumn([
			new ItemEditorSection('Customer', [
				new ItemEditorStaticField('customer', 'Name', undefined, undefined, undefined, ({firstName, lastName}) => `${firstName} ${lastName}`),
				new ItemEditorStaticField('customer.email', 'Email', 'mailto:', 'customer.email'),
				new ItemEditorStaticField('customer.phone', 'Phone'),
				new ItemEditorStaticBoolean('customer.isDisabled', 'Disabled'),
				new ItemEditorStaticDate('customer.lastLogin', 'Last Login')
			]),
			new ItemEditorSection('Pricing', [
				new ItemEditorStaticPrice('gatewayData.price.subtotal', 'Subtotal'),
				new ItemEditorStaticPrice('gatewayData.price.shipping', 'Shipping/Packaging'),
				new ItemEditorStaticPrice('gatewayData.price.tax.total', 'Tax'),
				new ItemEditorStaticPrice('gatewayData.price', 'Total', undefined, undefined, undefined, function(value) {
					return this.originalParser(value.subtotal + value.shipping + value.tax.total);
				})
			], 'narrow'),
			new ItemEditorContainer([
				new ItemEditorSection('Products', [
					new ItemEditorStaticTable('products', {
						tableId: 'products',
						createLinks: true,
						linkRoute: 'products',
						idColumn: 'product._id',
						columns: [
							new Column('Product Name', 'product.name'),
							new Column('Vintage', 'product.year'),
							new Column('UPC', 'product.upc', 'lg'),
							new PriceColumn('Price', 'unitPrice'),
							new Column('Qty', 'quantity')
						],
						search: false,
						disabledProperty: 'product.isDisabled',
						createLinkTest: (item: ProductData) => item.product._id !== 'promo'
					})
				], 'blocks')
			], 'full')
		], 'wide wrap')
	];

	protected loaded(response: WebServiceResponse): void {
		const {order} = response;
		const isDisabled: boolean = !!order.cancelledAt || order.customer && (order.customer.isDisabled || order.customer.isRemoved);
		const shipment = this.getSection('Shipment');
		const details = this.getSection('Order Details');
		const transField = this.getField('gatewayData.transactionId');
		const paymentField = this.getField('gatewayData.cardInfo') as ItemEditorStaticField;
		const chargedField = this.getField('chargedAt');
		const customer = this.getSection('Customer');

		this.order = order;

		// If this isn't a tasting kit, hide the Tasting Kit field
		if (order.type !== 'tastingKit') this.getField('kitName').isHidden = true;

		// Remove referred by field if there was no referral
		if (!order.promoCode || !order.promoCode.referralCustomer) this.getField('promoCode.referralCustomer').isHidden = true;

		// If this is a guest order, copy the guest info into the customer property
		// If customer has a limited account, say so in the customer section
		// Otherwise add a button to the member detail page
		if (order.guest) order.customer = order.guest;
		else if (order.customerType === 'limitedCustomer') customer.title += ' (Limited Account)';
		else customer.button = new ItemEditorSectionButton('address-card-o', 'More Details', `/members/${order.customer._id}`);
		
		// If there's a gift message, add the preformatted class to it - otherwise hide the Gift Message container
		if (order.giftMessage) this.getField('giftMessage').field.cssClass = 'preformatted';
		else this.getSection('Gift Message').container.isHidden = true;
		
		// If shipping, format for either a regular shipment or a FedEx location delivery
		// Otherwise hide the shipping information container
		if (order.shippingData.provider) {
			if (order.shippingData.destination.customerAddress) this.getField('shippingData.destination').field.label = 'Hold At';
			else this.getField('shippingData.destination.customerAddress').isHidden = true;
		} else {
			shipment.container.isHidden = true;
		}

		// Add extra bottles if necessary
		this.referralBottles = addExtraBottles(order);

		// Create button to package the order
		const shipBtn = new ItemEditorSectionButton('dropbox', 'Package', 'ship/' + order.orderId, '');

		// Create button to cancel the order
		const cancelBtn = new ItemEditorSectionButton('ban', order.chargedAt ? 'Refund' : 'Cancel Order', () => {

			this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/confirm-cancel.html'), {
				action: order.chargedAt ? (order.type === 'clubOrder' ? 'refund' : 'refund and cancel') : 'cancel'
			}, {
				heading: (order.chargedAt ? 'Refund' : 'Cancel') + ' Order',
				buttons: [
					new YesButton((dialog) => {
						details.isLoading = true;
						dialog.closeAll();
						const {price} = order.gatewayData;
						WebService.post('orders/cancelOrder', {id: order._id, total: price.subtotal + price.shipping + price.tax.total}).then(() => {
							location.reload();
						}, (error) => {
							this.error = error;
							details.isLoading = true;
						});
					}),
					new NoButton()
				]
			});
		});

		// Create button to charge a pending order
		const chargeBtn = new ItemEditorSectionButton('dollar', 'Charge', () => {
			this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/confirm-charge.html'), {}, {
				heading: 'Charge Order',
				buttons: [
					new YesButton((dialog) => {
						details.isLoading = true;
						dialog.close();
						WebService.post('orders/process', {id: order._id}).then((chargedResponse) => {
							order.gatewayData.transactionId = chargedResponse.transactionId;
							order.gatewayData.transactionUrl = chargedResponse.transactionUrl;
							order.chargedAt = chargedResponse.chargedAt;
							transField.populate(this, order);
							chargedField.populate(this, order);
							paymentField.addStaticLink(null);
							cancelBtn.label = 'Refund';
							details.buttons = [shipBtn, cancelBtn];
							details.isLoading = false;
						}, (error) => {
							this.error = error;
							details.isLoading = false;
						});
					}),
					new NoButton()
				]
			});
		});

		if (!order.chargedAt) {

			// If order hasn't been charged yet, show the Charge and Cancel buttons (unless customer is disabled or the order is cancelled)
			if (!isDisabled) details.buttons = [chargeBtn, cancelBtn];

			paymentField.addStaticLink(() => {
				Wallet.openDialog(this.app.dialogContainer, {customerId: order.customer._id, onSelect: (card) => {
					if (!card) return;
					if (card.token === order.gatewayData.paymentMethod) return;

					this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/confirm-change-card.html'), {}, {
						heading: 'Modify Payment',
						buttons: [
							new YesButton((dialog) => {
								details.isLoading = true;
								dialog.closeAll();
								WebService.post('orders/changePayment', {id: order._id, token: card.token}).then(() => {
									paymentField.field.value = paymentField.parseValue(card, null);
									order.gatewayData.paymentMethod = card.token;
									details.isLoading = false;
								}, (error) => {
									this.error = error;
									details.isLoading = false;
								});
							}),
							new NoButton()
						]
					});
				}});
			});

		} else if (!order.shippedAt) {

			// If order has been charged...

			// Show the Package and Cancel buttons
			if (!isDisabled) details.buttons = [shipBtn, cancelBtn];

			// If this order is shipping, add a button to allow changing the shipping address/method
			if (!isDisabled && order.shippingData.provider) shipment.button = new ItemEditorSectionButton('truck', 'Modify Shipping', () => {
				Rolodex.openDialog(this.app.dialogContainer, {customerId: order.customer._id, selectedAddress: order.shippingData.destination, onSelect: (address) => {
					if (!address && !order.shippingData.destination) return;

					shipment.isLoading = true;
					WebService.post('orders/changeAddressCheck', {id: order._id, address: address && address._id}).then((checkResponse) => {
						shipment.isLoading = false;

						if (checkResponse.difference < 0) return this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/change-address-more.html'), {
							refund: Price.fromCents(Math.abs(checkResponse.difference)).toString()
						}, {
							heading: 'Modify Shipping',
							buttons: [
								new OkButton()
							]
						});

						this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/confirm-change-address.html'), {
							refund: Price.fromCents(checkResponse.difference).toString(),
							address
						}, {
							heading: 'Modify Shipping',
							buttons: [
								new YesButton((dialog) => {
									shipment.isLoading = true;
									dialog.closeAll();
									WebService.post('orders/changeAddress', {id: order._id, address: address && address._id, difference: checkResponse.difference}).then(() => {
										location.reload();
									}, (error) => {
										this.error = error;
										shipment.isLoading = false;
									});
								}),
								new NoButton()
							]
						});
					}, (e) => {
						this.error = e;
						shipment.isLoading = false;
					});
				}});
			});

		} else if (!order.cancelledAt) {
			details.button = cancelBtn;

			if (!order.shippingData.provider) {

				// If order has been shipped and is in-store pickup, add a button to reprint the label
				details.buttons.unshift(new ItemEditorSectionButton('print', 'Reprint Label', () => {
					Ship.prototype.print.apply(this);
				}));

			}
		}

		super.loaded(response);
	}
}

/**
 * Adds promotional and referral bottles to the order details
 * @param {object} order The order details data from the orders webservice
 * @returns {number} The number of referral bottles added
 */
export function addExtraBottles(order: any): number {

	// Add promotional bottles if necessary
	if (order.promoCode && order.promoCode.extraBottles) order.products.push(new ExtraBottle('Promotional Bottle', order.promoCode.extraBottles));

	// Add referral bottles if necessary
	const referralBottles = order.type === 'clubOrder' && (order.shippedAt ? order.referralBottles : order.customer.referralBottles) || 0;
	if (referralBottles) order.products.push(new ExtraBottle('Referral Program Free Bottle', referralBottles));

	return referralBottles;
}

export class ExtraBottle implements ProductData {
	public readonly product: ProductData['product'];
	public readonly subtotal: number = 0;
	public readonly origPrice: number = 0;
	public readonly unitPrice: number = 0;

	constructor(name: string, public readonly quantity: number) {
		this.product = {
			_id: 'promo',
			name,
			hasImage: false
		};
	}
}

export interface ProductData {
	product: {
		_id: string;
		name: string;
		hasImage: boolean;
		upc?: string;
		bin?: string;
		grape?: string;
		updatedAt?: string;
		year?: string;
	};
	quantity: number;
	subtotal: number;
	origPrice: number;
	unitPrice: number;
}
