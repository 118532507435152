import {autoinject, viewResources, PLATFORM} from 'aurelia-framework';
import {App} from 'app';
import {Route} from 'route';
import {WebService} from 'webservice';
import {DateFormatter} from 'dateFormatter';
import {Progress} from 'elements/progress';
import io from 'socket.io-client/dist/socket.io';
import {StaticItemTableConfig, StaticItemTable} from 'elements/itemtable/static';
import {Column, DateColumn, ConcatColumn} from 'elements/itemtable/column';

@autoinject
@viewResources(
	PLATFORM.moduleName('elements/progress'),
	PLATFORM.moduleName('elements/itemtable/static')
)
export class Batch {
	private title: string;
	private route: Route;
	private batchId: string;
	private batch: BatchMessage;
	private details: any[];
	private isNew: boolean;
	private isLoading: boolean = true;
	private isRunning: boolean = false;
	private isSubscribed: boolean = false;
	private isReady: boolean = false;
	private schedules: {monthly?: Date | false, bimonthly?: Date | false, quarterly?: Date | false} = {};
	private scheduleDescription: string;
	private progress: Progress;
	private error?: string;
	private socket?: SocketIOClient.Socket;
	private dateFormatter = new DateFormatter({withTime: false, fullNames: true});

	private itemTable: StaticItemTable;
	private tableConfig: StaticItemTableConfig = {
		tableId: 'orders',
		title: 'Orders',
		idColumn: 'order.orderId',
		linkRoute: '/orders',
		createLinks: true,
		errorProperty: 'hasError',
		defaultSort: '-processedAt',
		columns: [
			new DateColumn('Processed', 'processedAt'),
			new DateColumn('Completed', 'completedAt'),
			new Column('Order ID', 'order.orderId'),
			new ConcatColumn('Member Name', ['order.customer.lastName', 'order.customer.firstName']),
			new Column('Status')
		],
		search: {
			columns: ['order.orderId', '__$memberName']
		}
	};

	constructor(private readonly app: App) {}

	private attached(): void {
		const {currentInstruction} = this.app.router;
		this.route = currentInstruction.config.settings.route;
		this.batchId = currentInstruction.params.id;
		this.isNew = this.batchId === 'new';
		this.title = this.isNew ? 'Process Orders' : 'Order Processing Batch';

		WebService.post('batches/details', {id: this.batchId}).then((response) => {
			if (this.isNew) {
				const {schedules} = response;
				if (schedules) for (const schedule of ['monthly', 'bimonthly', 'quarterly']) this.schedules[schedule] = schedules[schedule] && new Date(schedules[schedule]);
				if (!this.schedules.monthly && !this.schedules.bimonthly && !this.schedules.quarterly) {
					this.scheduleDescription = 'There are no pending ship dates this week, so batch processing cannot be used.';
				} else {
					this.isReady = true;
					this.scheduleDescription = 'This will process all pending ';
					
					if (schedules.bimonthly == schedules.quarterly && schedules.monthly == schedules.bimonthly) {
						this.scheduleDescription += 'orders scheduled to ship on ' + this.dateString('monthly');
					} else if (schedules.monthly && schedules.monthly == schedules.bimonthly) {
						this.scheduleDescription += 'monthly and every other month orders scheduled to ship on ' + this.dateString('monthly');
					} else if (schedules.monthly && schedules.monthly == schedules.quarterly) {
						this.scheduleDescription += 'monthly and quarterly orders scheduled to ship on ' + this.dateString('monthly');
					} else {
						const parts = [];
						if (this.schedules.monthly) parts.push('monthly orders scheduled to ship on ' + this.dateString('monthly'));
						if (this.schedules.bimonthly) parts.push('every other month orders scheduled to ship on ' + this.dateString('bimonthly'));
						if (this.schedules.quarterly) parts.push('quarterly orders scheduled to ship on ' + this.dateString('quarterly'));
						this.scheduleDescription += parts.join(' and ');
					}
					this.scheduleDescription += '.';
				}
			} else if (!response.batch) {
				this.error = "Could not load batch";
			} else {
				this.updateBatch(response.batch);
				if (this.batch.completedAt) {
					this.itemTable.rawData = this.batch.orders;
				} else {
					this.isRunning = true;
					this.subscribe(this.batchId);
				}
			}

			this.isLoading = false;
		});
	}

	private detached(): void {
		if (this.socket) this.socket.disconnect();
	}

	private dateString(schedule: 'monthly' | 'bimonthly' | 'quarterly'): string {
		return this.dateFormatter.format(this.schedules[schedule] as Date);
	}

	private updateBatch(batch: BatchMessage): void {
		const dateFormatter = new DateFormatter();
		this.batch = Object.assign(this.batch || {}, batch);

		let schedule = 'Every month';
		if (this.batch.schedule === 'bimonthly') schedule += ' + every other month';
		else if (this.batch.schedule === 'quarterly') schedule += ' + every quarter';
		else if (this.batch.schedule !== 'monthly') schedule = 'All members';

		this.details = [
			new BatchDetail('Schedule', schedule),
			new BatchDetail('Started', this.batch.createdAt ? dateFormatter.format(new Date(this.batch.createdAt)) : 'No'),
			new BatchDetail('Completed', this.batch.completedAt ? dateFormatter.format(new Date(this.batch.completedAt)) : 'No'),
			new BatchDetail('Total Orders', this.batch.count),
			new BatchDetail('Processed', this.batch.processed),
			new BatchDetail('Errors', this.batch.errorCount)
		];
	}

	private process(): void {
		if (!this.isReady) return;
		this.isRunning = true;
		this.itemTable.isBusy = true;

		WebService.post('batches/process').then(({batchId}) => {
			if (batchId) this.subscribe(batchId);
		});
	}

	private subscribe(batchId: string): void {
		if (!this.isRunning || this.isSubscribed) return;
		this.isSubscribed = true;
		this.itemTable.isBusy = true;
		this.itemTable.holdBusy = true;

		this.socket = io('/batch');
		this.socket.on('connected', () => {
			this.socket.emit('join', batchId);
		});
		this.socket.on('batch', (batch: BatchMessage) => {
			this.updateBatch(batch);
			this.progress.total = batch.count;
			this.progress.completed = batch.processed;
			if (batch.orders) this.itemTable.rawData = batch.orders;
		});
		this.socket.on('order', (order: OrderMessage) => {
			this.progress.completed = order.processed;
			this.updateBatch({
				processed: order.processed,
				errorCount: order.errorCount
			});
			if (order.order) this.itemTable.updateItem(order);
		});
		this.socket.on('completed', (completedAt) => {
			this.updateBatch({completedAt: completedAt})
		});
		this.socket.on('disconnect', () => {
			this.isSubscribed = false;
			this.itemTable.holdBusy = false;
			this.itemTable.isBusy = false;
		});
		this.socket.on('error', (error: string) => {
			this.error = error;
		});
	}
}

class BatchDetail {
	constructor(private readonly heading: string, private readonly value: string|number) {}
}

interface OrderMessage {
	order?: {
		_id: string;
		customer: {
			firstName: string;
			lastName: string;
		};
	};
	processedAt?: string;
	completedAt?: string;
	status?: string;
	processed: number;
	errorCount?: number;
	hasError?: boolean;
}

interface BatchMessage {
	schedule?: string;
	orders?: OrderMessage[];
	count?: number;
	processed?: number;
	errorCount?: number;
	createdAt?: string;
	completedAt?: string;
}
