import {autoinject} from 'aurelia-framework';
import {WebService} from 'webservice';
import {App} from 'app';
import {AppConfig} from 'appconfig';

@autoinject
export class SignOut {
	public config: typeof AppConfig = AppConfig;
	constructor(public readonly app: App) {}

	private activate(): void {
		WebService.get('sign-out').then(() => {
			this.config.auth = false;
			this.app.router.navigateToRoute('signin');
		});
	}
}
