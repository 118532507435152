import {ItemTableConfig, ItemTable} from 'elements/itemtable';
import {Column, DateColumn, BooleanColumn, EnumColumn} from 'elements/itemtable/column';
import {FilterConfig, FilterOption} from 'elements/itemtable/filter';
import {Report} from 'elements/itemtable/report';

export class Customers extends ItemTable {
	protected readonly showNewButton = false;
	public readonly title = 'Club Members';
	public readonly webService = 'customers';
	public readonly config: ItemTableConfig = {
		columns: [
			new Column('Last Name'),
			new Column('First Name'),
			new DateColumn('Registered', 'createdAt', 'lg'),
			new DateColumn('Last Login', undefined, 'lg'),
			new DateColumn('Completed Ratings', 'experience.completedAt', 'md'),
			new BooleanColumn('Selection Done', 'experience.order', 'sm'),
			new EnumColumn('Frequency', 'shipmentPreferences.schedule', {monthly: 'Every month', bimonthly: 'Every other month', quarterly: 'Every quarter'}, 'xs')
		],
		filters: [
			new FilterConfig('Frequency', [
				new FilterOption('All', {}),
				new FilterOption('EM', {'shipmentPreferences.schedule': 'monthly'}),
				new FilterOption('EOM', {'shipmentPreferences.schedule': 'bimonthly'}),
				new FilterOption('EQ', {'shipmentPreferences.schedule': 'quarterly'})
			]),
			new FilterConfig('Rated', [
				new FilterOption('All', {}),
				new FilterOption('Yes', {'experience.completedAt': {$ne: null}}),
				new FilterOption('No', {'experience.completedAt': null})
			]),
			new FilterConfig('Selection', [
				new FilterOption('All', {}),
				new FilterOption('Done', {'experience.order': {$ne: null}}),
				new FilterOption('Not Done', {'experience.order': null}),
				new FilterOption('Required', {selectionRequired: true})
			]),
			FilterConfig.statusFilter
		],
		reports: [
			new Report('Customers', 'customers')
		],
		search: {
			columns: ['firstName', 'lastName']
		},
		
	};
}
