import {ItemEditor, ItemEditorColumn, ItemEditorSection, ItemEditorContainer, ItemEditorSectionButton, ItemEditorSaveResponse} from 'elements/itemeditor';
import {ItemEditorInputField, ItemEditorStaticField, ItemEditorStaticDate, ItemEditorBooleanField, ItemEditorStaticTable, ItemEditorDropdownField, ItemEditorDropdownOption, ItemEditorAddressField} from 'elements/itemeditor/field';
import {InputField, TelInputField, EmailInputField, NumericInputField, ListEditorField, StaticItemTableField} from 'form';
import {Column, DateColumn, RatingColumn, PriceColumn, EnumColumn, IconColumn, CountColumn} from 'elements/itemtable/column';
import {WebServiceResponse} from 'webservice';
import {DateFormatter} from 'dateFormatter';
import {StaticItemTableConfig} from 'elements/itemtable/static';
import {PLATFORM} from 'aurelia-pal';
import {OkButton, CancelButton} from 'elements/dialog';
import {SelectedProduct, SelectedProductExtended} from 'elements/listeditor/selecteditem';
import {Price} from 'price';

const dateColumnFormatter = new DateFormatter({longFormat: false});

export class Member extends ItemEditor {
	public readonly webService = 'customers';
	protected readonly responseParam = 'customer';
	protected readonly itemType = 'Club Member';
	protected readonly allowNew = false;
	protected readonly cssClass = 'wrap';

	private customer: Customer;
	private shipmentMax: Price;
	private availableProducts: ProductDetails[];
	private availableAddedProducts: Product[];

	protected readonly columns = [
		new ItemEditorColumn([
			new ItemEditorSection('Customer Details', [
				new ItemEditorInputField('firstName', 'First Name', true, InputField),
				new ItemEditorInputField('lastName', 'Last Name', true, InputField),
				new ItemEditorInputField('email', 'Email', true, EmailInputField),
				new ItemEditorInputField('phone', 'Phone', true, TelInputField),
				new ItemEditorStaticDate('createdAt', 'Registered', 'Never'),
				new ItemEditorStaticDate('lastLogin', 'Last Login', 'Never'),
				new ItemEditorBooleanField('isDisabled', 'Disabled'),
				new ItemEditorStaticField('products', 'Products Rated', undefined, undefined, '0', (value: any[]): string => {
					return `${value.reduce((total, item) => total + (item.rating ? 1 : 0), 0)} / ${value.length}`
				}),
				new ItemEditorStaticField('eventRegistration.kit.name', 'Tasting Kit', 'kits', 'eventRegistration.kit._id'),
				new ItemEditorStaticField('referredBy', 'Referred By', 'members', 'referredBy._id', undefined, (value) => value && `${value.firstName} ${value.lastName}`)
			]),
			new ItemEditorSection('Shipment Preferences', [
				new ItemEditorDropdownField('shipmentPreferences.schedule', 'Frequency', [
					new ItemEditorDropdownOption('monthly', 'Every month'),
					new ItemEditorDropdownOption('bimonthly', 'Every other month'),
					new ItemEditorDropdownOption('quarterly', 'Every quarter')
				]),
				new ItemEditorInputField('shipmentPreferences.numberOfProducts', 'Unique Wines', true, NumericInputField),
				new ItemEditorInputField('shipmentPreferences.unitsPerProduct', 'Bottles of Each', true, NumericInputField),
				new ItemEditorAddressField('defaultAddress', 'addresses', 'Default Address', () => this.customer._id, this.app)
			]),
			new ItemEditorSection('Admin Notes', [
				new ItemEditorInputField('adminNotes', '')
			], 'blocks'),
			new ItemEditorSection('Cart', [
				new ItemEditorStaticTable('cart.products', new ProductTableConfig('cart', undefined, 'product.price.retail', false))
			])
		]),
		new ItemEditorColumn([
			new ItemEditorSection('Current Selection', [
				new ItemEditorStaticField('lastOrder.orderId', 'Order ID', 'orders', 'lastOrder.orderId', 'None'),
				new ItemEditorStaticDate('lastOrder.chargedAt', 'Charged'),
				new ItemEditorStaticDate('lastOrder.shippedAt', 'Shipped'),
				new ItemEditorStaticDate('experience.completedAt', 'Completed')
			]),
			new ItemEditorContainer([
				new ItemEditorSection('Next Selection', [
					new ItemEditorStaticField('nextOrder.orderId', 'Order ID', 'orders', 'nextOrder.orderId', 'Pending')
				], 'next-selection'),
				new ItemEditorSection(undefined, [
					new ItemEditorStaticTable('nextOrder.addedProducts', new ProductTableConfig('nextAdded', 'Member Added Wines', 'unitPrice', true, true)),
					new SelectionTable('nextOrder.products', new ProductTableConfig('nextClub', 'Club Wines', 'unitPrice', true, true))
				], 'no-sticky-headers')
			]),
			new ItemEditorSection('Member Wine History', [
				new ItemEditorStaticTable('products', {
					tableId: 'journal',
					createLinks: true,
					linkRoute: 'products',
					idColumn: 'productId',
					columns: [
						new Column('Product Name', 'details.name'),
						new Column('Vintage', 'details.year'),
						new Column('Total Qty', 'quantity'),
						new DateColumn('Last Ordered', 'lastOrderedAt', undefined, new DateFormatter({withDay: false, withTime: false})),
						new RatingColumn('Rating')
					],
					search: {columns: ['details.name']}
				})
			])
		], 'wide'),
		new ItemEditorColumn([
			new ItemEditorSection('Member Order History', [
				new ItemEditorStaticTable('orders', {
					tableId: 'orders',
					createLinks: true,
					linkRoute: 'orders',
					idColumn: 'orderId',
					defaultSort: 'createdAt',
					disabledProperty: 'cancelledAt',
					columns: [
						new Column('Order ID', 'orderId'),
						new IconColumn('Gift', 'isGift', 'gift'),
						new EnumColumn('Type', 'type', {tastingKit: 'Tasting Kit', storeOrder: 'Store Order', clubOrder: 'Club Order'}),
						new CountColumn('Items'),
						new PriceColumn('Total'),
						new DateColumn('Created', 'createdAt', undefined, dateColumnFormatter),
						new DateColumn('Charged', 'chargedAt', undefined, dateColumnFormatter),
						new DateColumn('Shipped', 'shippedAt', undefined, dateColumnFormatter)
					],
					search: {columns: ['orderId']},
					parseItem: (item) => {
						const {price} = item.gatewayData;
						item.total = price && (price.subtotal + price.tax.total + price.shipping);
						return item;
					}
				})
			])
		], 'full')
	];

	protected loaded(response: CustomerResponse): void {
		const {customer, products, orders, shipmentMax} = response;
		const {experiences, shipmentPreferences} = customer;

		const blackoutDate = new Date(response.blackoutDate) as Date;
		const processingDate = new Date(response.processingDate) as Date;
		const selectionDate = new Date(response.selectionDate) as Date;
		const registeredDate = new Date(customer.createdAt);
		const now = new Date();

		this.customer = customer;
		this.shipmentMax = shipmentMax[shipmentPreferences.numberOfProducts] && Price.fromDollars(shipmentMax[shipmentPreferences.numberOfProducts]);
		customer.orders = orders;

		// Remove referred by field if there was no referral
		if (!customer.referredBy) this.getField('referredBy').isHidden = true;

		// Convert registration date string to a Date
		for (const order of orders) order.createdAt = new Date(order.createdAt);

		// Populate journal with product details
		// Loop through the products in the journal
		for (const journalProduct of customer.products) {
			const id = journalProduct.productId = journalProduct.details as string;
			journalProduct.details = products.find((details) => details._id === id);
			journalProduct.quantity = 0;

			// Loop through member's orders for each product in the journal
			for (const order of orders) {

				// Look for the journal product in the order and move on to the next order if it's not found
				const orderProduct = order.products.find(({product}) => product === id);
				if (!orderProduct) continue;

				// Add the product quantity for this order to the total quantity
				journalProduct.quantity += orderProduct.quantity || 0;

				// Update the product's "last ordered" date if this is the newest order found
				if (!journalProduct.lastOrderedAt || order.createdAt > journalProduct.lastOrderedAt) journalProduct.lastOrderedAt = order.createdAt as Date;
			}

		}

		// Retrieve the current experience, last order and next order
		customer.experience = experiences && experiences[experiences.length - 1];
		customer.lastOrder = experiences && orders.find((order) => order._id === (experiences.length < 2 ? customer.eventRegistration.order : experiences[experiences.length - 2].order));
		customer.nextOrder = experiences && orders.find((order) => order._id === customer.experience.order);
		customer.selectionIsComplete = !!customer.nextOrder;

		if (customer.nextOrder) {

			// If the next order exists, split out anything the customer added to the order into a separate list
			customer.nextOrder.addedProducts = customer.nextOrder.products.filter((product) => product.isFromStore);
			customer.nextOrder.products = customer.nextOrder.products.filter((product) => !product.isFromStore);

		} else {

			// If the next order hasn't been created, make the lists
			customer.nextOrder = {
				addedProducts: customer.nextShipmentCart && customer.nextShipmentCart.products || [],
				products: []
			};

		}

		// Populate the cart and next order product lists with product details
		for (const list of [
			customer.cart.products,
			customer.nextOrder.addedProducts,
			customer.nextOrder.products
		] as Product[][]) if (list) for (const listProduct of list) {
			const id = listProduct.productId = listProduct.product as string;
			listProduct.product = products.find((product) => product._id === id);
		}

		if (!customer.nextOrder.chargedAt) {
			const addedProductsTable = (this.getField('nextOrder.addedProducts') as ItemEditorStaticTable);
			const selectionProductsTable = (this.getField('nextOrder.products') as SelectionTable);

			if (customer.selectionIsComplete) {
				addedProductsTable.field.config.editButton = () => {
					this.modifyAddedWines();
				};
				selectionProductsTable.field.config.editButton = () => {
					this.selectWines();
				};
			} else {

				// If the next order hasn't been created yet, the "added products" list doesn't have unit prices...
				// Populate them from the retail price
				for (const product of customer.nextOrder.addedProducts) product.unitPrice = (product.product as ProductDetails).price.retail;

				// Add the "select wines" button
				this.getSection('Next Selection').button = new ItemEditorSectionButton('check-square-o', 'Select Wines', () => {
					const messages: string[] = [];
					const lastShipped = customer.lastOrder && customer.lastOrder.shippedAt && new Date(customer.lastOrder.shippedAt);

					if (!customer.experience.completedAt && (customer.lastOrder || !customer.fromLimitedAccount)) {
						if (lastShipped) {
							if (lastShipped > blackoutDate) messages.push(
								`
									${customer.lastOrder.type === 'clubOrder' ? 'Previous club order' : 'Tasting kit'} was packaged too recently (${DateFormatter.format(lastShipped, {withDay: false, withTime: false, longFormat: false})})
									and member has not rated their wines yet
								`
							);
						} else {
							this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/selection-error-notshipped.html'), {}, {
								heading: 'Next Selection',
								buttons: [
									new OkButton()
								]
							});
							return;
						}
						if (now < selectionDate) messages.push(
							`
								The deadline for Every ${shipmentPreferences.schedule === 'quarterly' ? 'Quarter' : (shipmentPreferences.schedule === 'bimonthly' ? 'Other Month' : 'Month')} members to
								rate their wines isn't until ${DateFormatter.format(selectionDate, {withDay: false, withTime: false, longFormat: false})}
							`
						);
					}
					if (messages.length) {
						this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/selection-warning.html'), {messages: messages}, {
							heading: 'Next Selection',
							buttons: [
								new OkButton((dialog) => {
									dialog.closeAll();
									this.selectWines();
								}),
								new CancelButton()
							]
						});
					} else {
						this.selectWines();
					}
				});

			}

			// Add the required length param to the selection table
			selectionProductsTable.requiredLength = shipmentPreferences.numberOfProducts;

		}

		// Get a list of products that were added to the next selection by the member
		this.availableAddedProducts = Array.from(this.customer.nextOrder.addedProducts);

		// Get a list of products that are available to pick for the next selection
		this.availableProducts = products.filter(({_id, isNewVintageOf, isDisabled, isRemoved, price}) => {

			// If product is already selected for the next order, allow it regardless of status
			if (this.customer.nextOrder.products.find((product) => product.productId === _id)) return true;

			// Filter out disabled and removed products, and anything without a price
			if (isDisabled || isRemoved || !price || !price.retail) return false;

			// Function to test if a product (or a different vintage of the same product) is in a list
			function check(rawFilterProducts: (Product|JournalProduct)[], property: string = 'product'): boolean {
				if (!rawFilterProducts) return false;
				const filterProducts = rawFilterProducts.map((filterProduct) => products.find((product) => product._id === (filterProduct[property]._id || filterProduct[property])));
				return checkVintage(filterProducts, _id);
			}

			// Function to iterate down the hierarchy of vintages
			function checkVintage(filterProducts: ProductDetails[], parentId: string): boolean {
				if (!parentId) return false;
				const parent = products.find((product) => product._id === parentId);
				return !!filterProducts.find((filterProduct) => filterProduct._id === parent._id || filterProduct.isNewVintageOf === parent._id) || checkVintage(filterProducts, parent.isNewVintageOf);
			}

			// Filter out anything in the customer's cart and anything they've added to their next shipment
			const {cart, nextOrder, products: journalProducts} = customer;
			if (cart && check(cart.products)) return false;
			if (nextOrder && check(nextOrder.addedProducts)) return false;

			// Filter out anything the customer has already had
			if (check(journalProducts, 'details')) return false;
			if (orders.find((order) => !!check(order.products))) return false;

			return true;
		});

		// Done with the custom stuff
		super.loaded(response);

	}

	private modifyAddedWines(): void {
		const addedWinesField = this.getField('nextOrder.addedProducts').field as StaticItemTableField;
		const availableConfig = new ProductTableConfig('addedAvailable', 'Test', 'product.price.retail', false, false);
		availableConfig.staticItems = this.availableAddedProducts;
		const listEditor = new ListEditorField('addedToSelection', undefined, 'products', {
			tableConfig: availableConfig,
			ThisSelectedItem: SelectedProductExtended
		});
		listEditor.value = addedWinesField.value.map((product) => product.productId);
		this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/select-wines.html'), {listEditor}, {
			heading: 'Member Added Wines',
			buttons: [
				new OkButton((dialog) => {
					const {value} = listEditor;
					addedWinesField.value = this.availableAddedProducts.filter((product) => value.includes(product.productId));
					dialog.closeAll();
				}),
				new CancelButton()
			]
		});
	}

	private selectWines(): void {
		const selectedWinesField = this.getField('nextOrder.products').field;
		const listEditor = new ListEditorField('nextSelection', undefined, 'products', {
			tableConfig: {
				columns: [
					new Column('Product Name', 'name'),
					new Column('Vintage', 'year'),
					new PriceColumn('Price', 'price.retail')
				],
				search: {
					columns: ['name']
				},
				staticItems: this.availableProducts
			},
			showFooter: true,
			maxSelection: this.customer.shipmentPreferences.numberOfProducts,
			maxPrice: this.shipmentMax,
			ThisSelectedItem: SelectedProduct
		});
		listEditor.value = selectedWinesField.value.map(({productId}) => productId);
		this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/select-wines.html'), {listEditor}, {
			heading: 'Next Selection',
			buttons: [
				new OkButton((dialog) => {
					const selectedProducts: Product[] = listEditor.value.map((selectedProduct) => {
						const product = this.availableProducts.find(({_id}) => _id === selectedProduct);
						return <Product> {
							productId: selectedProduct,
							product,
							quantity: this.customer.shipmentPreferences.unitsPerProduct,
							origPrice: product.price.retail,
							unitPrice: product.price.retail, // TODO: Show discounted price
							subtotal: product.price.retail * this.customer.shipmentPreferences.unitsPerProduct
						};
					});
					selectedWinesField.value = selectedProducts;
					dialog.closeAll();
				}),
				new CancelButton()
			]
		});
	}

	protected saved(saveData: {[index: string]: any}, error?: string, response?: ItemEditorSaveResponse): void {
		if (!error && saveData.nextOrder && response.orderId) {
			const field = this.getField('nextOrder.orderId') as ItemEditorStaticField;
			field.field.value = response.orderId;
			field.createLink(field.field.value, field.field.value);
			this.getSection('Next Selection').button = undefined;
		} 
		super.saved(saveData, error, response);
	}
}

class ProductTableConfig implements StaticItemTableConfig {
	public readonly createLinks = true;
	public readonly linkRoute = 'products';
	public readonly idColumn = 'productId';
	public readonly search = false;
	public readonly columns: (string|Column)[];
	public readonly disabledProperty = 'product.isDisabled';
	public staticItems?: any[];

	constructor(
		public readonly tableId: string,
		public readonly title: string,
		priceProperty: string,
		public readonly hideEmpty = true,
		public readonly isSavable = false
	) {
		this.columns = [
			new Column('Product Name', 'product.name'),
			new Column('Vintage', 'product.year'),
			new PriceColumn('Price', priceProperty),
			new Column('Qty', 'quantity')
		];
	}
}

class SelectionTable extends ItemEditorStaticTable {
	public requiredLength: number;

	constructor(path: string, config: StaticItemTableConfig) {
		super(path, config);
		this.field.validate = (): boolean => {
			const {value} = this.field;
			this.field.flag(this.requiredLength && value && value.length && value.length !== this.requiredLength ? `Selection should be exactly ${this.requiredLength} wines` : null);
			return this.field.isValid;
		}
	}
}

interface ProductDetails {
	_id: string;
	name: string;
	year: number;
	isDisabled?: boolean;
	isRemoved?: boolean;
	isNewVintageOf?: string;
	currentVintage?: string;
	price: {
		retail: number;
	}
}

interface Product {
	_id: string;
	product: string|ProductDetails;
	quantity: number;
	productId?: string;
	origPrice?: number;
	unitPrice?: number;
	subtotal?: number;
	isFromStore?: boolean;
}

interface JournalProduct {
	_id: string;
	productId?: string;
	details: string|ProductDetails;
	rating: number;
	quantity?: number;
	lastOrderedAt: Date;
}

interface Experience {
	createdAt: string;
	orderedAt: string;
	completedAt: string;
	order: string;
	products: string[];
}

interface Address {
	_id: string;
	firstName: string;
	lastName: string;
	company?: string;
	streetAddress: string;
	extendedAddress?: string;
	locality: string;
	region: string;
	postalCode: string;
	phone: string;
	isBusiness: boolean;
	defaultProvider?: string;
	defaultService?: string;
}

interface Customer {
	_id: string;
	email: string;
	phone: string;
	firstName: string;
	lastName: string;
	lastLogin: string;
	isDisabled?: boolean;
	fromLimitedAccount?: string;
	referredBy: string;
	cart: {
		updatedAt: string;
		products: Product[];
	};
	nextShipmentCart: {
		updatedAt: string;
		products: Product[];
	};
	products: JournalProduct[];
	eventRegistration: {
		kit: {
			_id: string;
			name: string;
		};
		order: string;
	};
	shipmentPreferences: {
		numberOfProducts: number;
		unitsPerProduct: number;
		schedule: 'monthly'|'bimonthly'|'quarterly';
	};
	experiences: Experience[];
	addresses: Address[];
	defaultAddress?: string;
	referralBottles?: number;
	adminNotes?: string;
	experience?: Experience;
	createdAt: string;

	// View-specific params
	lastOrder?: Order;
	nextOrder?: Order;
	selectionIsComplete?: boolean;
	nextShipDate?: Date;
	orders?: Order[];
}

interface Order {
	_id?: string;
	type?: 'tastingKit'|'storeOrder'|'clubOrder';
	kitName?: string;
	orderId?: string;
	products: Product[];
	addedProducts?: Product[];
	createdAt?: string|Date;
	chargedAt?: string;
	shippedAt?: string;
}

interface CustomerResponse extends WebServiceResponse {
	customer: Customer;
	products: ProductDetails[];
	orders: Order[];
	blackoutDate: number;
	processingDate: number;
	selectionDate: number;
}
