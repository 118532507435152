import {ItemEditor, ItemEditorColumn, ItemEditorSection} from "elements/itemeditor";
import {ItemEditorInputField, ItemEditorUploadField} from "elements/itemeditor/field";

export class Winery extends ItemEditor {
	public readonly webService = 'wineries';
	protected readonly responseParam = 'winery';

	protected readonly columns = [
		new ItemEditorColumn([
			new ItemEditorSection('Winery Details', [
				new ItemEditorInputField('name', 'Name', true),
				new ItemEditorInputField('country', 'Country'),
				new ItemEditorInputField('region', 'Region'),
				new ItemEditorInputField('subregion', 'Subregion'),
				new ItemEditorInputField('vineyard', 'Vineyard')
			]),
			new ItemEditorSection('Winemaker Photo', [
				new ItemEditorUploadField('winemaker.image', '', {winemaker: true})
			])
		], 'no-grow'),
		new ItemEditorColumn([
			new ItemEditorSection('Winemaker Profile', [
				new ItemEditorInputField('winemaker.profile', '')
			], 'blocks')
		], 'wide')
	];
}
