import {ItemTable, ItemTableConfig} from "elements/itemtable";
import {EnumColumn, CountColumn, DateColumn} from "elements/itemtable/column";

export class Batches extends ItemTable {
	public readonly webService = 'batches';
	public readonly linkTarget = '_self';
	public readonly title = 'Order Processing Batches';
	public readonly config: ItemTableConfig = {
		columns: [
			new DateColumn('Created', 'createdAt'),
			new DateColumn('Completed', 'completedAt'),
			new EnumColumn('Schedule', 'schedule', {monthly: 'Every month', bimonthly: 'Every month + every other month', quarterly: 'Every month + every quarter', all: 'All'}),
			new CountColumn('Orders')
		],
		search: false
	};
}
