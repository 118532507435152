import {ItemTableConfig, ItemTable} from 'elements/itemtable';
import {Column, PriceColumn, BooleanColumn} from 'elements/itemtable/column';
import {FilterConfig, FilterOption} from 'elements/itemtable/filter';
import {Report} from 'elements/itemtable/report';

export class Products extends ItemTable {
	public readonly webService = 'products';
	public readonly config: ItemTableConfig = {
		columns: [
			'Name',
			new Column('Winery', 'origin.winery.name', 'xl'),
			new Column('Vintage', 'year'),
			new PriceColumn('Price', 'price.retail'),
			new BooleanColumn('Featured', 'isFeatured', 'lg'),
			new BooleanColumn('Public', 'isPublic', 'lg'),
			new BooleanColumn('Image', 'hasImage', 'md'),
			new BooleanColumn('Video', 'video', 'md'),
			new Column('UPC', 'upc', 'lg')
		],
		filters: [
			new FilterConfig('Featured', [
				new FilterOption('All', {}),
				new FilterOption('Yes', {'isFeatured': true}),
				new FilterOption('No', {'isFeatured': {$ne: true}})
			]),
			new FilterConfig('Public', [
				new FilterOption('All', {}),
				new FilterOption('Yes', {'isPublic': true}),
				new FilterOption('No', {'isPublic': {$ne: true}})
			]),
			new FilterConfig('Available', [
				new FilterOption('All', {}),
				new FilterOption('Yes', {'isDisabled': {$ne: true}}),
				new FilterOption('No', {'isDisabled': true})
			])
		],
		reports: [
			new Report('Product List', 'product-list')
		],
		search: {
			columns: ['name', 'origin.winery.name', 'upc']
		}
	};
}
