import {ItemTableConfig, ItemTable} from '../elements/itemtable';

export class Wineries extends ItemTable {
	public readonly webService = 'wineries';
	public readonly config: ItemTableConfig = {
		columns: [
			'Name',
			'Country',
			'Region'
		]
	};
}
